import React, { useEffect } from "react";
import { Col, Row, Container, Table } from "react-bootstrap";
import FAQ from "../components/faq";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { SIGNUP_URL } from "../util/constants";
import { graphql, Link, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import Testimonial from "../components/testimonial";
import Skel from "../components/skel";

const STATUS_GA = "available";
const STATUS_BETA = "beta";

const MENU_SYNC_INFERRED = "inferred";

const POSStatus = ({
  name,
  image,
  catalogSync,
  realtimeUpdates,
  menuSync,
  instantSetup,
  status,
  integrationLink = null,
}) => {
  return (
    <tr>
      <td>
        {image ? (
          <Img
            className="max-width-auto"
            fluid={image.childImageSharp.fluid}
            loading="eager"
            fadeIn={false}
            alt={name}
          />
        ) : (
          <b>{name}</b>
        )}
      </td>
      <td>{catalogSync && <i className="fas fa-check"></i>}</td>
      <td>{realtimeUpdates && <i className="fas fa-check"></i>}</td>
      <td>
        {menuSync && <i className="fas fa-check"></i>}
        {menuSync !== true && (
          <>
            <br />
            <small>[{menuSync}]</small>
          </>
        )}
      </td>
      <td>{instantSetup && <i className="fas fa-check"></i>}</td>
      <td>
        {status === STATUS_BETA && (
          <>
            Private Beta
            <br />
            <small>Contact us to join</small>
          </>
        )}
        {status === STATUS_GA && (
          <>
            <i className="fas fa-check"></i>

            {integrationLink && (
              <>
                <br />
                <small>
                  <Link to={integrationLink}>Integration guide</Link>
                </small>
              </>
            )}
          </>
        )}
      </td>
    </tr>
  );
};

const POSIntegrations = () => {
  const data = useStaticQuery(graphql`
    query {
      posPromo: file(
        relativePath: { eq: "integrations/pos-hero.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      wallTv3: file(relativePath: { eq: "mockups/wall-tv-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      phoneAndLaptop: file(
        relativePath: { eq: "mockups/phone-and-laptop-01.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      printMenuTable: file(
        relativePath: { eq: "mockups/print-menu-table-cropped.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      moonrakerLogo: file(
        relativePath: { eq: "customer-logos/moonraker.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      bareArmsLogo: file(relativePath: { eq: "customer-logos/bare-arms.png" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      finalGravityLogo: file(
        relativePath: { eq: "customer-logos/final-gravity.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      pubDogLogo: file(relativePath: { eq: "customer-logos/pubdog.png" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      gotabLogo: file(relativePath: { eq: "integrations/gotab-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      squareLogo: file(relativePath: { eq: "integrations/square-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      cloverLogo: file(relativePath: { eq: "integrations/clover-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }

      heartlandLogo: file(relativePath: { eq: "integrations/heartland-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  return (
    <Skel dark noHeader>
      <SEO
        title={"Instant Digital TV Menus from your Point of Sale System"}
        description={
          "Link your POS to Taplist.io for an instant, auto-updating TV and QR menu. Make changes on your POS and your TVs automatically update."
        }
      />
      <section className="section" style={{ paddingTop: 30, paddingBottom: 0 }}>
        <Container>
          {/* <Row>
          <Col md={3}>
            <Link to="/integrations">&laquo; Integrations</Link>
          </Col>
        </Row> */}
          <Row>
            <Col
              className="justify-content-center text-center"
              md={{ span: 8, offset: 2 }}
            >
              <h1>
                Automatic Digital TV Menus from your Bar, Brewery, or Restaurant
                POS
              </h1>
              <p className="lead">
                Taplist.io is the battle-tested leader in rich, easy-to-manage
                digital menus. Now it's easier than ever with the point-of-sale
                system you may already have.
              </p>
            </Col>
          </Row>
          <Row>
            <Col className="justify-content-center text-center">
              <Img
                className="max-width-auto"
                fluid={data.posPromo.childImageSharp.fluid}
                loading="eager"
                fadeIn={false}
                alt="A brewer menu shown on a full-screen TV, with a laptop showing the Taplist.io management dashboard."
              />
            </Col>
          </Row>
        </Container>
      </section>

      {/* <section className="gray-bg overflow-hidden" style={{ padding: 30 }}>
        <Container>
          <Row>
            <Col className="justify-content-center text-center">
              <p class="lead">
                Try out the management dashboard now, no credit card required.
              </p>
              <a
                className="m-btn m-btn-theme2nd m-btn-radius"
                href={SIGNUP_URL}
              >
                Get Started
              </a>
            </Col>
          </Row>
        </Container>
      </section> */}

      <section id="overview" className="section effect-section">
        <Container>
          <Row className="md-m-25px-b m-45px-b justify-content-center text-center">
            <Col lg={8}>
              <h3 className="h1 m-15px-b">
                {/* Beautiful, Always-Accurate Menus */}
                No New Workflows.
                <br />
                Tons of New Power.
              </h3>
              <p className="m-0px font-2">
                When you link your POS to Taplist.io, you get a{" "}
                <b>rich, battle-tested digital menu system</b> where your point
                of sale remains the <b>source of truth</b> for product, menu,
                and inventory data. Your team doesn't have to learn a new tool.
              </p>
            </Col>
          </Row>
          <Row className="md-m-25px-b m-45px-b justify-content-center">
            <Col lg={4}>
              <div className="border-radius-10 box-shadow-hover hover-top white-bg p-20px-tb">
                <div className="p-30px">
                  <h3 className="m-15px-b">TV Menu Boards</h3>
                  <Img
                    className="max-width-auto"
                    fluid={data.wallTv3.childImageSharp.fluid}
                    loading="eager"
                    alt="A successfully paired Taplist.io TV device"
                  />
                  <p>
                    Link one or more TVs to your account, customize the
                    appearance, and watch your menus update instantly.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="border-radius-10 box-shadow-hover hover-top white-bg p-20px-tb">
                <div className="p-30px">
                  <h3 className="m-15px-b">Web/QR Menus</h3>
                  <Img
                    className="max-width-auto"
                    fluid={data.phoneAndLaptop.childImageSharp.fluid}
                    loading="eager"
                    alt="A beverage menu web page"
                  />
                  <p>
                    Get an instant QR code beer and beverage menu, and embed
                    your menu on any web page.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="border-radius-10 box-shadow-hover hover-top white-bg p-20px-tb">
                <div className="p-30px">
                  <h3 className="m-15px-b">Printed Menus</h3>
                  <Img
                    className="max-width-auto"
                    fluid={data.printMenuTable.childImageSharp.fluid}
                    loading="eager"
                    alt="A printed Taplist.io menu"
                  />
                  <p>
                    Automatically generate and download custom, printable PDFs
                    of your food and beverage menus.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="font-2 font-w-600  justify-content-center text-center">
            <Col lg={8}>
              <p>
                <b>Save Time. Look Great. Sell More.</b> We're famous for being
                the simple, easy-to-use digital menu system that helps you look
                great and stays out of your way.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="testimonials" className="section gray-bg">
        <Row className="md-m-25px-b m-45px-b justify-content-center text-center">
          <Col lg={8}>
            <h3 className="h1 m-15px-b">Supported Systems</h3>
            <p className="m-0px font-2">
              We have first-class integrations with many of the best
              Point-of-Sale systems.
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="justify-content-center text-center" md={{ span: 8 }}>
            <Table
              className="feature-table"
              responsive={"md"}
              hover
              style={{ width: "100%" }}
            >
              <thead>
                <th>POS</th>
                <th>Catalog Sync</th>
                <th>Realtime Updates</th>
                <th>Menu Sync</th>
                <th>Instant Setup</th>
                <th>Status</th>
              </thead>
              <tbody>
                <POSStatus
                  name={"GoTab"}
                  image={data.gotabLogo}
                  catalogSync
                  realtimeUpdates
                  menuSync
                  instantSetup
                  status={STATUS_GA}
                  integrationLink={"/integrations/gotab"}
                />
                <POSStatus
                  name={"Square"}
                  image={data.squareLogo}
                  catalogSync
                  realtimeUpdates
                  menuSync={'1'}
                  instantSetup
                  status={STATUS_BETA}
                  integrationLink={"/integrations/square"}
                />
                <POSStatus
                  name={"Clover"}
                  image={data.cloverLogo}
                  catalogSync
                  realtimeUpdates
                  menuSync={'2'}
                  instantSetup
                  status={STATUS_BETA}
                />
                <POSStatus
                  name={"Heartland POS"}
                  image={data.heartlandLogo}
                  catalogSync
                  realtimeUpdates
                  menuSync
                  instantSetup
                  status={STATUS_BETA}
                />
                <tr>
                  <td>
                    <b>Arryved</b>
                  </td>
                  <td colspan={5}>
                    <em>In development, contact us for early access.</em>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Toast</b>
                  </td>
                  <td colspan={5}>
                    <em>In development, contact us for early access.</em>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 8, offset: 2 }}>
            <div className="small text-muted">
              <ol>
                <li>
                  "Square for Restaurants" menus cannot yet be imported due to a Square
                  limitation.
                </li>
                <li>
                  Clover does not have a built-in concept of / structure for
                  menus. Menu structure is generated by Taplist.io.
                </li>
              </ol>
            </div>
          </Col>
        </Row>
      </section>

      <section id="testimonials" className="section">
        <Row className="md-m-25px-b m-45px-b justify-content-center text-center">
          <Col lg={8}>
            <h3 className="h1 m-15px-b">Trusted All Over The Planet</h3>
            <p className="m-0px font-2">
              You'll be in good company: Try us and see why{" "}
              <b>over 1,000 customers</b> have chosen or switched to Taplist.io.
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Testimonial
            customerName={<>Pub Dog Pizza &amp; Drafthouse</>}
            customerLocation={"Columbia, MD"}
            customerLogo={data.pubDogLogo}
            description={
              <div>
                <p>
                  Taplist.io was very easy to set up and we had our first menu
                  created in minutes. A great product for a great price with
                  world-class support.
                </p>
              </div>
            }
          />
          <Testimonial
            customerName={"Moonraker Brewing Company"}
            customerLocation={"Auburn, CA"}
            customerLogo={data.moonrakerLogo}
            description={
              <div>
                <p>
                  We've tried other software and self-made templates and
                  Taplist.io is definitely superior. It has made ordering a
                  breeze across the board.
                </p>
              </div>
            }
          />
          <Testimonial
            customerName={"Bare Arms Brewing"}
            customerLocation={"Waco, TX"}
            customerLogo={data.bareArmsLogo}
            description={
              <div>
                <p>
                  We've been using Taplist.io for over 3 years now and our staff
                  and customers absolutely love it. It's easy for our staff to
                  make menu changes as our beers change.
                </p>
              </div>
            }
          />
        </Row>
        <Row className="font-2 font-w-600  justify-content-center text-center">
          <Col lg={8}>
            <p>
              <b>Our risk-free guarantee:</b> We're a friendly, family-run
              team&mdash;and we stand behind our product. All of our plans come
              with our money-back guarantee. If you're not happy, we'll make it
              right.
            </p>
            {/* <p>
                <small>
                Since 2016, fewer than 1% of our customers have <em>ever</em>{" "}
                experienced a service issue or asked for a refund.
                </small>
              </p> */}
          </Col>
        </Row>
      </section>

      
      }

      <section className="overflow-hidden gray-bg" style={{ padding: 30 }}>
        <Container>
          <Row>
            <Col className="justify-content-center text-center">
              <p class="lead">
                See what your menu would look like in just a few clicks, no
                credit card required.
              </p>
              <a
                className="m-btn m-btn-theme2nd m-btn-radius"
                href={SIGNUP_URL}
              >
                Get Started
              </a>
            </Col>
          </Row>
        </Container>
      </section>
    </Skel>
  );
};

export default POSIntegrations;
